* {
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
    vertical-align: top;
  }
  .gallery {
    display: flex;
    margin: 10px auto;
    max-width: 600px;
    position: relative;
    padding-top: 66.6666666667%;
  }
  @media screen and (min-width: 600px) {
    .gallery {
      padding-top: 400px;
    }
  }
  .gallery__img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .gallery__thumb {
    padding-top: 6px;
    margin: 6px;
    display: block;
  }
  .gallery__selector {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  .gallery__selector:checked + .gallery__img {
    opacity: 1;
  }
  .gallery__selector:checked ~ .gallery__thumb > img {
    box-shadow: 0 0 0 3px #0be2f6;
  }
  