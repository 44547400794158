.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.dash-widget-header {
  -moz-box-align: center;
  align-items: center;
  display: -moz-box;
  display: flex;
  margin-bottom: 15px;
}

.dash-widget-icon {
  -moz-box-align: center;
  align-items: center;
  display: -moz-inline-box;
  display: inline-flex;
  font-size: 1.875rem;
  height: 50px;
  -moz-box-pack: center;
  justify-content: center;
  line-height: 48px;
  text-align: center;
  width: 50px;
  border: 3px solid;
  border-radius: 50px;
  padding: 28px;
}

.dash-count {
  font-size: 18px;
  margin-left: auto;
}

.ahmed{
  /* align-items: center; */
  align-self: center;
  /* background-color: red; */
  margin-right: 70px;
}
.safdar{
  margin-left: 22px;
    margin-top: 8px;
    margin-bottom: 8px;
}




.navActive{
  background-color: white !important;
  color: black;
}


.section3125 {
  float: left;
  width: 100%;
  min-height: 90vh;
}