.card__success {
    position: absolute;
    top: -50px;
    left: 205px;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: #03bafe !important;
    border: 5px solid #fff;
  }

  

  /* .card_color{
    background-color: #03bafe !important;

    position: absolute;
    top: -50px;
    left: 205px;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 5px solid #fff;



  } */
  .card__success i {
    color: #fff;
    line-height: 100px;
    font-size: 60px;
  }
